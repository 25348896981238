import React, {useCallback, useContext, useMemo, useState} from 'react'
import Text from '@component/Text/Text'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import useQueryGetUserProfile from '@hook/query/portfolio/useQueryGetUserProfile'
import {useLoginStore} from '@store/LoginStore'
import useFormatNumber from '@hook/useFormatNumber'
import HorizontalDivider from '@component/divider/HorizontalDivider'
import CurrentDateBadge from '@feature/common/components/PortfolioShareModal/component/CurrentDateBadge'
import {useTranslation} from 'next-i18next'
import useQueryGetUserGraph from '@hook/query/portfolio/useQueryGetUserGraph'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'

interface IProps {
    className?: string
    isFirst?: boolean
    isCapture?: boolean
}

export const renderChartColor = ['#9A5AF5', '#22C6FC', '#FF5B60']
const PortfolioDisplay: React.FC<IProps> = ({className, isFirst, isCapture}) => {
    const {t} = useTranslation()
    const loginId = useLoginStore(state => state.id)
    const [chartWidth, setChartWidth] = useState(100)
    const [chartPercent, setChartPercent] = useState([])
    const {themeColor, colorStyle, shareItem, currentDate} = useContext(SnsShareContext)

    const {data} = useQueryGetUserProfile(loginId)

    const {displayPercent, displayNumber} = useFormatNumber()

    const {chartGraphData, refresh} = useQueryGetUserGraph(loginId, parseInt(currentDate))

    const portfolioTokenList = useMemo(() => {
        const sortArr = data?.token_distribution?.sort((a, b) => b?.percentage - a?.percentage)

        const top3List = sortArr?.slice(0, 3).map((item, idx) => {
            return {token: item?.symbol, key: item?.symbol, percent: item?.percentage, color: renderChartColor?.at(idx)}
        })
        return top3List
    }, [data])

    const reversePortfolioList = useMemo(() => {
        return portfolioTokenList ? [...portfolioTokenList]?.reverse() : []
    }, [portfolioTokenList])

    const renderMark = useCallback(
        (idx: number | string) => {
            switch (idx) {
                case 0:
                    return 'bg-[#FF5B60]'
                case 1:
                    return 'bg-[#22C6FC]'
                case 2:
                    return 'bg-[#9A5AF5]'
                case 'others':
                    if (themeColor === 'primary') {
                        return 'bg-[#EEEEEE4A]'
                    } else {
                        return 'bg-[#00000026]'
                    }
            }
        },
        [themeColor],
    )

    const calcStrokeOffset = useCallback(
        (item, idx) => {
            if (reversePortfolioList?.length === 2) {
                if (idx === reversePortfolioList?.length - 1) {
                    return 280 * (item?.percent * 0.00985)
                } else {
                    const ar = [...chartPercent].reverse()
                    return 280 * (ar?.at(idx) * 0.0115)
                }
            }
            if (idx === reversePortfolioList?.length - 1) {
                return 282 * (item?.percent * 0.01)
            } else {
                // 만약 해당 요소가 첫 번째면 끝까지 채워야하니 조금 더 추가
                if (idx === 0) {
                    const ar = [...chartPercent].reverse()
                    return 282 * (ar?.at(idx) * 0.0102)
                } else {
                    const ar = [...chartPercent].reverse()
                    return 282 * (ar?.at(idx) * 0.01)
                }
            }
        },
        [chartPercent, reversePortfolioList?.length],
    )

    useIsomorphicLayoutEffect(() => {
        let num = 0
        const reverseArr = [...reversePortfolioList]?.reverse()
        if (chartWidth - reverseArr?.at(0)?.percent > 0 && reverseArr?.length > 0) {
            reverseArr?.map(item => {
                setChartWidth(prev => prev - Number(item?.percent.toFixed(2)))
                num += item?.percent
                chartPercent?.push(num)
                setChartPercent([...chartPercent])
            })
        }
    }, [reversePortfolioList])

    return isFirst ? (
        <div className={'flex flex-col justify-center items-center px-[25px]'}>
            <Text className={`text-[15px] font-semibold ${colorStyle.text}`}>{t('modal.snsShare.card.portfolio')}</Text>
            <div className={'mt-[10px]'}>
                <div className={'w-[110px] h-[110px] relative mx-auto'}>
                    <svg
                        className={'absolute top-0 left-0 rotate-[-90deg] overflow-hidden rounded-full'}
                        xmlns={'http://www.w3.org/2000/svg'}
                        width={110}
                        height={110}>
                        <circle
                            className={`fill-none`}
                            cx={55}
                            cy={55}
                            r={45}
                            strokeLinecap={'round'}
                            stroke={colorStyle.chartColor.portfolio.base}
                            strokeWidth={20}
                            strokeDasharray={280}
                            strokeDashoffset={0}
                        />
                        {reversePortfolioList?.map((item, idx) => {
                            return (
                                <>
                                    <circle
                                        key={item?.token}
                                        id={`id-[${item?.token}]`}
                                        ref={el => {
                                            if (el) {
                                                el.style.setProperty(
                                                    'stroke-dashoffset',
                                                    portfolioTokenList?.length > 1
                                                        ? `${280 - calcStrokeOffset(item, idx) + 10}px`
                                                        : `0`,
                                                    'important',
                                                )
                                            }
                                        }}
                                        className={`fill-none relative ${
                                            idx === reversePortfolioList?.length - 1 &&
                                            'drop-shadow-[-2px_-0px_2.5px_rgba(0,0,0,0.3)]'
                                        }`}
                                        cx={55}
                                        cy={55}
                                        r={45}
                                        strokeLinecap={'round'}
                                        stroke={
                                            portfolioTokenList?.length > 1
                                                ? renderChartColor?.slice(-portfolioTokenList?.length).at(idx)
                                                : '#FF5B60'
                                        }
                                        strokeWidth={20}
                                        strokeDasharray={280}
                                    />
                                </>
                            )
                        })}
                    </svg>
                </div>
            </div>
            <div className={'mt-[10px] mb-[20px] w-full'}>
                {portfolioTokenList?.map((item, idx) => {
                    return (
                        <div className={'flex justify-between items-center'} key={item?.token}>
                            <div className={'flex items-center space-x-[7px] flex-1'}>
                                <div className={`w-[7px] h-[7px] rounded-full ${renderMark(idx)}`}></div>
                                <Text className={`text-[13px] ${colorStyle?.nameColor}`}>{item?.token}</Text>
                            </div>
                            <Text className={`text-body2 flex-1 text-right ${colorStyle.text}`}>
                                {displayPercent(item?.percent)}
                            </Text>
                        </div>
                    )
                })}
                {data?.token_distribution.length > 3 && (
                    <div className={'flex justify-between items-center'}>
                        <div className={'flex items-center space-x-[7px] flex-1'}>
                            <div className={`w-[7px] h-[7px] rounded-full ${renderMark('others')}`}></div>
                            <Text className={`text-[13px] ${colorStyle?.nameColor}`}>
                                {t('modal.snsShare.card.portfolio.other')}
                            </Text>
                        </div>
                        <Text className={`text-body2 flex-1 text-right ${colorStyle.text}`}>
                            {displayPercent(chartWidth)}
                        </Text>
                    </div>
                )}
                <div>
                    {data?.token_distribution?.length === 0 && shareItem?.length === 1 && (
                        <div>
                            <Text className={`text-body2 text-[13px] text-center ${colorStyle?.text}`}>
                                {t('modal.snsShare.share.noAsset.text')}
                            </Text>
                        </div>
                    )}
                </div>
            </div>
            {shareItem?.length > 1 && (
                <div className={'relative mb-[20px] w-full'}>
                    <HorizontalDivider
                        className={`absolute w-[90px] top-[50%] translate-y-[-50%] left-0 ${colorStyle.dateTagColor}`}
                    />
                    <CurrentDateBadge
                        allDate={chartGraphData?.length}
                        className={'w-fit mx-auto px-[7px] py-[3px] relative rounded-[15px] text-[11px] text-white'}
                    />
                    <HorizontalDivider
                        className={`absolute w-[90px] top-[50%] translate-y-[-50%] right-0 ${colorStyle.dateTagColor}`}
                    />
                </div>
            )}
        </div>
    ) : (
        <div className={`${data?.token_distribution?.length > 0 ? 'my-[15px]' : 'my-[5px]'} px-[25px]`}>
            {portfolioTokenList?.map((item, idx) => {
                return (
                    <div className={'flex justify-between items-center'} key={item?.token}>
                        <div className={'flex items-center space-x-[7px] flex-1'}>
                            <div className={`w-[7px] h-[7px] rounded-full ${renderMark(idx)}`}></div>
                            <Text className={`text-[13px] ${colorStyle?.nameColor}`}>{item?.token}</Text>
                        </div>
                        <Text className={`text-body2 flex-1 text-right ${colorStyle.text}`}>
                            {displayPercent(item?.percent)}
                        </Text>
                    </div>
                )
            })}
            {data?.token_distribution.length > 3 && (
                <div className={'flex justify-between items-center'}>
                    <div className={'flex items-center space-x-[7px] flex-1'}>
                        <div className={`w-[7px] h-[7px] rounded-full ${renderMark('others')}`}></div>
                        <Text className={`text-[13px] ${colorStyle?.nameColor}`}>
                            {t('modal.snsShare.card.portfolio.other')}
                        </Text>
                    </div>
                    <Text className={`text-body2 flex-1 text-right ${colorStyle.text}`}>
                        {displayPercent(chartWidth)}
                    </Text>
                </div>
            )}
            {data?.token_distribution?.length === 0 && (
                <div>
                    <Text className={`text-body2 text-[13px] text-center ${colorStyle?.text}`}>
                        {t('modal.snsShare.share.noAsset.text')}
                    </Text>
                </div>
            )}
        </div>
    )
}

export default PortfolioDisplay
