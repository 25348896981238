import React, {useContext} from 'react'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import WizSimpleAreaChart from '@component/charts/WizAreaChart/WizSimpleAreaChart'

interface Props {
    width: number
    height: number
    data: {x: number; y: number}[]
    type: TypeShareChart
    className?: string
}

type TypeShareChart = 'profit' | 'balance' | 'earning'

const PortfolioShareChart = ({width, height, data, type, className}: Props) => {
    const {colorStyle} = useContext(SnsShareContext)

    if (!data || data?.length === 0) {
        return null
    }

    return (
        <WizSimpleAreaChart
            data={data}
            chartType={type}
            width={width}
            height={height}
            borderWidth={2}
            colorPalette={{
                line: {
                    plus: colorStyle.chartColor.plus,
                    minus: colorStyle.chartColor.minus,
                },
            }}
            className={className}
        />
    )
}

export default React.memo(PortfolioShareChart)
