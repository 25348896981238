import React, {useContext, useMemo, useRef, useState} from 'react'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import {useRouter} from 'next/router'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import Text from '@component/Text/Text'
import UserThumbnail from '@component/images/UserThumbnail'
import ReferralBackQR from '@feature/common/components/PortfolioShareModal/component/ReferralBackQR'
import {useTranslation} from 'next-i18next'
import ic_default_user from '@image/ic_default_user.webp'

interface IProps {
    className?: string
    cardBackgroundImage?: string
    isCapture?: boolean
}

const BackViewContainer: React.FC<IProps> = ({className, cardBackgroundImage, isCapture = false}) => {
    const {t} = useTranslation()
    const router = useRouter()
    const {data: me} = useQueryFetchMe(undefined, false)
    const {colorStyle, option, themeColor, randomNumber} = useContext(SnsShareContext)

    const cardRef = useRef<HTMLDivElement>(null)

    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    const [opacity, setOpacity] = useState(0)

    const renderJoinDate = useMemo(() => {
        return new Date(me?.created_at * 1000).toLocaleDateString(router?.locale, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        })
    }, [me, router?.locale])

    const getStyleToFontLength = useMemo(() => {
        const name = router?.locale === 'ko' ? me?.nicknames?.at(0)?.nickname || me?.name : me?.name

        if (name?.length < 11) {
            return 'text-[19px] leading-[23px] font-bold'
        } else {
            return 'text-[15px] leading-[18px] break-all font-bold'
        }
    }, [me, router?.locale])

    const getStyleThumbnailBackgroundColor = useMemo(() => {
        if (themeColor === 'primary') {
            return 'bg-[#2A3547]'
        } else if (themeColor === 'blue') {
            return 'bg-[#A2B6E3] border border-[#2A3547] '
        }
    }, [themeColor])

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        const rect = cardRef.current.getBoundingClientRect()
        const mouseX = e.clientX - rect.left
        const mouseY = e.clientY - rect.top

        setOpacity(1)

        setX(mouseX)
        setY(mouseY)
    }
    const onMouseLeave = () => {
        setOpacity(0)
    }

    return (
        <div
            ref={cardRef}
            style={{transform: !isCapture && `rotateY(180deg) translate3d(0,0,0.1px)`}}
            className={`absolute w-full h-full rounded-[20px] bg-cover ${cardBackgroundImage} `}
            onMouseLeave={onMouseLeave}
            onMouseMove={onMouseMove}>
            <div
                style={{
                    filter: `brightness(1.2) opacity(${opacity})`,
                    backgroundImage: `radial-gradient(circle farthest-side at ${x}px ${y}px, #3A7B7080 0%, transparent 100%)`,
                    mixBlendMode: 'color-dodge',
                }}
                className={'absolute top-0 w-full h-full rounded-[20px]'}
            />

            <div className={'flex flex-col h-full pt-[30px] p-[20px]'}>
                <div className={'flex flex-col items-center justify-center h-full flex-1'}>
                    <ReferralBackQR />
                    <Text className={`mt-[10px] mb-[15px] text-[11px] text-center w-full ${colorStyle.text}`}>
                        {t('modal.snsShare.card.qr.text')}
                    </Text>
                    <Text className={`text-ti1 text-center ${colorStyle.text}`}>
                        {t('modal.snsShare.card.referral.code', {code: me?.my_referral_code})}
                    </Text>
                </div>
                {option.displayName ? (
                    <div className={'py-[20px] px-[10px] flex gap-[10px] items-center'}>
                        <div>
                            <UserThumbnail
                                imageClassName={`${getStyleThumbnailBackgroundColor}`}
                                src={me?.thumbnail || ic_default_user.src}
                                size={50}
                            />
                        </div>
                        <div>
                            <Text className={`${getStyleToFontLength} ${colorStyle.text}`}>
                                {router?.locale === 'ko' ? me?.nicknames?.at(0)?.nickname || me?.name : me?.name}
                            </Text>
                            <Text className={`text-ti4 ${colorStyle.text} opacity-70`}>
                                {t('modal.snsShare.card.createAt.text', {date: renderJoinDate})}
                            </Text>
                        </div>
                    </div>
                ) : (
                    <div className={'py-[20px] flex items-center justify-center'}>
                        <Text
                            className={`${randomNumber === 6 ? 'text-ti1' : 'text-h3'} ${colorStyle.text} text-center`}>
                            {t(`modal.snsShare.card.displayName.hide.text${randomNumber}`)}
                        </Text>
                    </div>
                )}
            </div>
        </div>
    )
}

export default BackViewContainer
