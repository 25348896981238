import React, {useCallback, useContext, useMemo, useRef, useState} from 'react'
import Text from '@component/Text/Text'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {useRouter} from 'next/router'
import lot_logo from '@image/app_logo.png'
import ShareCaptureLogo from '@svg/portfolio/share_lot_logo.svg'
import WizImage from '@component/images/WizImage'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import ProfitDisplay from '@feature/common/components/PortfolioShareModal/card/ProfitDisplay'
import BalanceDisplay from '@feature/common/components/PortfolioShareModal/card/BalanceDisplay'
import EarningsDisplay from '@feature/common/components/PortfolioShareModal/card/EarningsDisplay'
import PortfolioDisplay from '@feature/common/components/PortfolioShareModal/card/PortfolioDisplay'
import ReferralQR from '@feature/common/components/PortfolioShareModal/component/ReferralQR'
import {useTranslation} from 'next-i18next'
import useQueryGetUserGraph from '@hook/query/portfolio/useQueryGetUserGraph'
import {useLoginStore} from '@store/LoginStore'
import Spinner from '@component/Spinner/Spinner'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'

interface IProps {
    className?: string
    cardBackgroundImage?: string
    isCapture?: boolean
}

type TypeShareItem = 'profit' | 'balance' | 'earning' | 'portfolio'

const FrontViewContainer = ({className, cardBackgroundImage, isCapture = false}: IProps) => {
    const {t} = useTranslation()
    const router = useRouter()
    const loginId = useLoginStore(state => state.id)
    const {shareItem, colorStyle, option, currentDate} = useContext(SnsShareContext)

    const {chartGraphData} = useQueryGetUserGraph(loginId, 1000)
    const {data: me} = useQueryFetchMe(undefined, false)

    const [isCheck, setIsCheck] = useState(true)
    const [isSpinner, setIsSpinner] = useState(true)

    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    const [opacity, setOpacity] = useState(0)

    const cardRef = useRef<HTMLDivElement>(null)

    const renderShareContent = useCallback(
        (item: TypeShareItem) => {
            const isCheckFirst = item === shareItem?.at(0)

            switch (item) {
                case 'profit':
                    return <ProfitDisplay key={item} isFirst={isCheckFirst} isCapture={isCapture} />
                case 'balance':
                    return <BalanceDisplay key={item} isFirst={isCheckFirst} isCapture={isCapture} />
                case 'earning':
                    return <EarningsDisplay key={item} isFirst={isCheckFirst} isCapture={isCapture} />
                case 'portfolio':
                    return <PortfolioDisplay key={item} isFirst={isCheckFirst} isCapture={isCapture} />
            }
        },
        [shareItem, isCapture],
    )

    const isCheckChartData = useMemo(() => {
        if (isCheck) {
            return true
        } else {
            return false
        }
    }, [isCheck])

    useIsomorphicLayoutEffect(() => {
        if (chartGraphData) {
            if (chartGraphData?.length > 0) {
                setIsCheck(true)
            } else {
                setIsCheck(false)
            }
            setIsSpinner(false)
        } else {
            setIsSpinner(true)
        }
    }, [chartGraphData])

    // const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    //     const rect = cardRef.current.getBoundingClientRect()
    //     const mouseX = e.clientX - rect.left
    //     const mouseY = e.clientY - rect.top
    //
    //     setOpacity(1)
    //
    //     setX(mouseX)
    //     setY(mouseY)
    // }

    const onMouseLeave = () => {
        setOpacity(0)
    }

    return (
        <div
            ref={cardRef}
            style={{transform: 'translate3d(0,0,0px)'}}
            className={`absolute w-full h-full bg-cover bg-[#2A3547] rounded-[21px] ${cardBackgroundImage}`}
            onMouseLeave={onMouseLeave}
            onMouseMove={() => {
                //onMouseMove
            }}>
            {/*<div*/}
            {/*    style={{*/}
            {/*        filter: `brightness(1.2) opacity(${opacity})`,*/}
            {/*        backgroundImage: `radial-gradient(circle farthest-side at ${x}px ${y}px, #3A7B7080 0%, transparent 100%)`,*/}
            {/*        mixBlendMode: 'color-dodge',*/}
            {/*    }}*/}
            {/*    className={'absolute top-0 w-full h-full rounded-[21px] transition-all ease-in-out duration-500'}*/}
            {/*/>*/}

            <div className={'h-full flex flex-col p-[20px]'}>
                <div className={'flex justify-between'}>
                    <div className={'flex items-center gap-[8px] '}>
                        {isCapture ? (
                            <div className={'flex-none'}>
                                <ShareCaptureLogo />
                            </div>
                        ) : (
                            <WizImage src={lot_logo} width={23} containerClassName={'flex-none'} />
                        )}
                        <div className={colorStyle?.verified}>
                            <Text className={`text-ti4`}>{t('modal.snsShare.card.verified.text')}</Text>
                            {option?.displayName && (
                                <Text className={'text-[11px] leading-[14px] break-all'}>
                                    {router?.locale === 'ko' ? me?.nicknames?.at(0)?.nickname || me?.name : me?.name}
                                </Text>
                            )}
                        </div>
                    </div>
                    <ReferralQR size={40} />
                </div>

                <div className={'flex flex-col justify-center h-full gap-[5px]'}>
                    {shareItem.length === 0 ? (
                        <div>
                            <Text className={`text-body2 ${colorStyle?.text} flex-1 text-center`}>
                                {t('modal.snsShare.card.emptyItem')}
                            </Text>
                        </div>
                    ) : isCheckChartData && !isSpinner ? (
                        shareItem?.map((item: TypeShareItem) => renderShareContent(item))
                    ) : (
                        <div>
                            {isSpinner ? (
                                <Spinner
                                    visible={isSpinner}
                                    size={'small'}
                                    color={colorStyle?.QRCodeFgColor}
                                    className={'justify-center mx-auto'}
                                />
                            ) : (
                                <Text className={`text-body2 ${colorStyle?.text} flex-1 text-center`}>
                                    {t('modal.snsShare.card.emptyData')}
                                </Text>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default React.memo(FrontViewContainer)
