import React, {useContext, useLayoutEffect, useMemo, useState} from 'react'
import Text from '@component/Text/Text'
import {useLoginStore} from '@store/LoginStore'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import useFormatNumber from '@hook/useFormatNumber'
import useQueryGetUserGraph from '@hook/query/portfolio/useQueryGetUserGraph'
import {getDateFromYYYYMMDD} from '@util/dates'
import PortfolioShareMainNumber from '@feature/common/components/PortfolioShareModal/component/PortfolioShareMainNumber'
import PortfolioShareChart from '@feature/common/components/PortfolioShareModal/component/PortfolioShareChart'
import CurrentDateBadge from '@feature/common/components/PortfolioShareModal/component/CurrentDateBadge'
import {useTranslation} from 'next-i18next'
import useQueryGetUserProfile from '@hook/query/portfolio/useQueryGetUserProfile'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'

interface IProps {
    className?: string
    isFirst?: boolean
    isCapture?: boolean
}

const BalanceDisplay: React.FC<IProps> = ({className, isFirst, isCapture}) => {
    const {t} = useTranslation()
    const loginId = useLoginStore(state => state.id)
    const [renderText, setRenderText] = useState(0)

    const {data} = useQueryGetUserProfile(loginId)
    const {currentDate, colorStyle} = useContext(SnsShareContext)

    const {displayBalance} = useFormatNumber()
    const {chartGraphData, refresh} = useQueryGetUserGraph(loginId, parseInt(currentDate))

    const chartData = useMemo(() => {
        if (!chartGraphData || chartGraphData.length === 0) return []

        const allData =
            currentDate !== '1000'
                ? chartGraphData?.slice(-parseInt(currentDate), chartGraphData?.length)
                : chartGraphData

        return allData?.map(item => {
            const date = getDateFromYYYYMMDD(item.day).getTime()

            if (item?.balance) {
                return {x: date, y: item.balance}
            } else {
                return {x: date, y: 0}
            }
        })
    }, [currentDate, chartGraphData])

    useIsomorphicLayoutEffect(() => {
        if (chartData?.length > 0) {
            setRenderText(data?.total_value)
        }
    }, [data, chartData])

    return isFirst ? (
        <div className={'flex flex-col justify-center items-center'}>
            <Text className={`text-[15px] font-semibold ${colorStyle.text}`}>{t('modal.snsShare.card.balance')}</Text>
            <PortfolioShareMainNumber text={renderText} type={'balance'} isCapture={isCapture} />
            <CurrentDateBadge allDate={chartGraphData?.length} />
            <div className={`mb-[15px] ${chartData?.length > 0 && 'h-[90px] '}`}>
                <PortfolioShareChart
                    data={chartData}
                    width={230}
                    height={90}
                    type={'balance'}
                    className={'top-[-5px]'}
                />
            </div>
        </div>
    ) : (
        <div className={'flex justify-between items-center px-[25px]'}>
            <Text className={`text-[13px] ${colorStyle?.nameColor}`}>{t('modal.snsShare.card.balance')}</Text>
            <Text
                enablePreWhiteSpace={false}
                className={`text-[15px] font-semibold whitespace-nowrap break-normal ${colorStyle.text}`}>
                {displayBalance(renderText, {showPreSign: true})}
            </Text>
        </div>
    )
}

export default BalanceDisplay
