import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import Text from '@component/Text/Text'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import {useTranslation} from 'next-i18next'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {useLoginStore} from '@store/LoginStore'
import useQueryGetUserGraph from '@hook/query/portfolio/useQueryGetUserGraph'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
}

const EditCurrentDateContainer: React.FC<IProps> = ({className}) => {
    const {data} = useQueryFetchMe()
    const {t} = useTranslation()
    const {currentDate, setCurrentDate} = useContext(SnsShareContext)
    const loginId = useLoginStore(state => state.id)
    const {chartGraphData, refresh} = useQueryGetUserGraph(loginId, 1000)

    const [isData, setIsData] = useState(false)

    const currentDateList = useMemo(() => {
        return [
            {id: '7', displayText: t('modal.snsShare.card.date.7')},
            {id: '30', displayText: t('modal.snsShare.card.date.30')},
            {id: '90', displayText: t('modal.snsShare.card.date.90')},
            {id: '180', displayText: t('modal.snsShare.card.date.180')},
            {id: '365', displayText: t('modal.snsShare.card.date.365')},
            {id: '1000', displayText: t('modal.snsShare.card.date.1000')},
        ]
    }, [t])

    const getCreateAt = useCallback(
        currentDate => {
            const createAt = new Date(data?.created_at * 1000)
            const deadline = new Date(createAt).setDate(createAt.getDate() + Number(currentDate))

            if (isData) {
                return new Date().getTime() > deadline
            } else {
                return false
            }
        },
        [isData, data?.created_at],
    )

    const getCurrentDateStyle = useCallback(
        data => {
            if (!getCreateAt(data?.id) && data?.id !== '1000') {
                return 'border-gray07 text-gray04 pointer-events-none'
            }
            if (currentDate === data?.id) {
                return 'border-primary bg-primary_tint02'
            } else {
                return 'border-gray05'
            }
        },
        [currentDate, getCreateAt],
    )

    useEffect(() => {
        const createAt = new Date(data?.created_at * 1000)
        const deadline = new Date(createAt).setDate(createAt.getDate() + 7)

        if (new Date().getTime() < deadline) {
            setCurrentDate('1000')
        }
    }, [data?.created_at, setCurrentDate])

    useEffect(() => {
        if (chartGraphData?.length > 0) {
            setIsData(true)
        } else {
            setIsData(false)
        }
    }, [chartGraphData])

    const onChangePeriod = item => {
        if (item?.id === '1000' || getCreateAt(item?.id)) {
            createLog('event', 'portfolio_share_change_period', {period: item?.id})
            setCurrentDate(item?.id)
        }
    }

    return (
        <div>
            <Text className={'text-ti2 text-gray01'}>{t('modal.snsShare.edit.option.date')}</Text>
            <div className={'mt-[5px] grid grid-cols-3 gap-[5px]'}>
                {currentDateList?.map(item => {
                    return (
                        <div
                            key={item?.id}
                            onClick={() => onChangePeriod(item)}
                            className={`py-[12px] border rounded-[3px] cursor-pointer relative ${getCurrentDateStyle(
                                item,
                            )}`}>
                            <Text className={'text-body2 text-center'}>{item?.displayText}</Text>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default EditCurrentDateContainer
