import React, {useCallback, useContext, useMemo} from 'react'
import Text from '@component/Text/Text'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import useSnackbar from '@hook/useSnackbar'
import {useTranslation} from 'next-i18next'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
}

const EditShareItemContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {shareItem, setShareItem} = useContext(SnsShareContext)
    const {showSnackbar} = useSnackbar()

    const shareDataList = useMemo(() => {
        return [
            {id: 'profit', displayText: t('modal.snsShare.card.profit')},
            {id: 'balance', displayText: t('modal.snsShare.card.balance')},
            {id: 'earning', displayText: t('modal.snsShare.card.earnings')},
            {id: 'portfolio', displayText: t('modal.snsShare.card.portfolio')},
        ]
    }, [t])

    const isActiveShareItem = useCallback(
        data => {
            if (shareItem?.find(item => item === data?.id)) {
                return true
            } else {
                return false
            }
        },
        [shareItem],
    )

    const getActiveShareItemStyle = useCallback(
        data => {
            if (isActiveShareItem(data)) {
                return 'border-primary bg-primary_tint02'
            } else {
                return 'border-gray05'
            }
        },
        [isActiveShareItem],
    )

    const getActiveShareItemNumberingStyle = useCallback(
        data => {
            if (isActiveShareItem(data)) {
                return 'bg-primary '
            } else {
                return 'border border-gray06'
            }
        },
        [isActiveShareItem],
    )

    const renderShareItemNumbering = useCallback(
        data => {
            if (shareItem?.find(item => item === data?.id)) {
                return shareItem?.indexOf(data?.id) + 1
            } else {
                return
            }
        },
        [shareItem],
    )

    const onChangeFunc = data => {
        if (shareItem?.find(item => item === data?.id)) {
            createLog('event', 'portfolio_share_change_options_remove', {option: data?.id})
            setShareItem(shareItem?.filter(l => l !== data?.id))
        } else {
            createLog('event', 'portfolio_share_change_options_add', {option: data?.id})
            setShareItem([...shareItem, data?.id])
        }
    }

    return (
        <div className={'pb-[20px]'}>
            <Text className={'text-ti2 text-gray01 '}>{t('modal.snsShare.edit.option.items')}</Text>
            <div className={'mt-[5px] grid grid-cols-2 gap-[5px]'}>
                {shareDataList?.map(data => {
                    return (
                        <div
                            key={data?.id}
                            onClick={() => {
                                onChangeFunc(data)
                            }}
                            className={`py-[12px] border rounded-[3px] cursor-pointer relative ${getActiveShareItemStyle(
                                data,
                            )}`}>
                            <Text className={'text-body2 text-center'}>{data?.displayText}</Text>
                            <div
                                className={`absolute w-[20px] h-[20px] rounded-full top-[5px] right-[5px] flex items-center justify-center ${getActiveShareItemNumberingStyle(
                                    data,
                                )}`}>
                                {isActiveShareItem && (
                                    <Text className={'text-ti4 text-white'}>{renderShareItemNumbering(data)}</Text>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default EditShareItemContainer
