import React, {useContext} from 'react'
import Text from '@component/Text/Text'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import WizSwitch from '@component/switchs/WizSwitch'
import {useTranslation} from 'next-i18next'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
}

export interface IShareTheme {
    id: string
    colorClass: TypeShareTheme
}

export type TypeShareTheme = 'primary' | 'blue'
const EditDisplayNameContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {option, setOption} = useContext(SnsShareContext)

    const onChangeDisplayName = () => {
        createLog('event', 'portfolio_share_change_display_name', {display_name_state: option?.displayName})
        setOption({...option, displayName: !option?.displayName})
    }

    return (
        <div className={`flex gap-x-[5px] justify-between ${className}`}>
            <Text className={'text-ti2 flex-1'}>{t('modal.snsShare.edit.option.displayUser')}</Text>
            <WizSwitch
                checked={option?.displayName}
                onChanged={onChangeDisplayName}
                className={`${option?.displayName ? 'dark:bg-primary' : 'dark:bg-gray04'}`}
            />
        </div>
    )
}

export default EditDisplayNameContainer
