import React, {useContext} from 'react'
import QR from 'qrcode.react'
import {useRouter} from 'next/router'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import share_qrcode_logo from '@image/sns_qrcode_logo.png'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'

interface IProps {
    className?: string
}

const ReferralBackQR: React.FC<IProps> = ({className}) => {
    const router = useRouter()
    const {data: me} = useQueryFetchMe()
    const {colorStyle, themeColor} = useContext(SnsShareContext)

    return (
        <div>
            <QR
                size={130}
                level={'Q'}
                renderAs={'svg'}
                fgColor={themeColor === 'primary' ? colorStyle?.QRCodeFgColor : '#2A3547'}
                bgColor={themeColor === 'primary' ? '#2A3547' : colorStyle?.QRCodeFgColor}
                value={`https://leagueoftraders.io${router.locale === 'ko' ? '/ko' : ''}/login?referral=${
                    me?.my_referral_code
                }`}
                imageSettings={{
                    src: `${share_qrcode_logo.src}`,
                    height: 40,
                    width: 40,
                    excavate: true,
                }}
            />
        </div>
    )
}

export default ReferralBackQR
