import React, {useContext, useLayoutEffect, useState} from 'react'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import FrontViewContainer from '@feature/common/components/PortfolioShareModal/card/FrontViewContainer'
import BackViewContainer from '@feature/common/components/PortfolioShareModal/card/BackViewContainer'
import WizImage from '@component/images/WizImage'
import logo_white from '@image/lot_logo_white.png'
import {useTranslation} from 'next-i18next'
import useFormatDate from '@hook/useFormatDate'
import Text from '@component/Text/Text'
import {useRouter} from 'next/router'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'

interface IProps {
    className?: string
}

const ShareImageContainer = ({className}: IProps, ref: any) => {
    const router = useRouter()
    const {t} = useTranslation()
    const {viewPage, themeColor} = useContext(SnsShareContext)
    const [shareCardImage, setShareCardImage] = useState({front: '', back: ''})
    const [shareBackgroundImage, setShareBackgroundImage] = useState('')

    const {getUTCTimeStamp} = useFormatDate()

    useIsomorphicLayoutEffect(() => {
        if (themeColor === 'primary') {
            setShareBackgroundImage(`bg-[url('/images/share_image_bg_primary.png')]`)
            setShareCardImage({
                front: "bg-[url('/images/img_share_card_bg.png')]",
                back: "bg-[url('/images/img_share_card_bg_back.png')]",
            })
        } else if (themeColor === 'blue') {
            setShareBackgroundImage(`bg-[url('/images/share_image_bg_blue.png')]`)
            setShareCardImage({
                front: "bg-[url('/images/img_share_card_bg_blue.png')]",
                back: "bg-[url('/images/img_share_card_bg_blue_back.png')]",
            })
        }
    }, [themeColor])

    return (
        <div
            id={'share_image'}
            ref={ref}
            className={`w-[600px] h-[600px] ${shareBackgroundImage} flex flex-col items-center justify-center ${className}`}>
            <div className={'mx-auto w-[300px] h-[500px] relative'}>
                {viewPage === 'front' ? (
                    <FrontViewContainer cardBackgroundImage={shareCardImage?.front} isCapture={true} />
                ) : (
                    <div>
                        <BackViewContainer cardBackgroundImage={shareCardImage?.back} isCapture={true} />
                    </div>
                )}
            </div>
            <div className={'mt-[10px] space-y-[5px]'}>
                <WizImage src={logo_white} width={173} />
            </div>
            <div className={'mt-[5px] w-full'}>
                <div className={'mt-[6px] w-full'}>
                    <div className={'mt-[8px] w-full'}>
                        {router?.locale === 'ko' ? (
                            <Text className={'text-white text-body3 text-center'}>
                                {/*{displayDate(new Date().getTime() / 1000, t('dateFormat.dateAndTimeAndTimezone'))}*/}
                                {getUTCTimeStamp(new Date(), 'ko')} UTC
                            </Text>
                        ) : (
                            <Text className={'text-white text-body3 text-center'}>
                                {getUTCTimeStamp(new Date())} UTC
                            </Text>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.forwardRef(ShareImageContainer)
