import React, {useContext, useMemo} from 'react'
import useFormatNumber from '@hook/useFormatNumber'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import Text from '@component/Text/Text'

interface IProps {
    className?: string
    text?: number
    type?: TypeShareNumber
    isCapture?: boolean
}

type TypeShareNumber = 'profit' | 'balance' | 'earning'

const PortfolioShareMainNumber: React.FC<IProps> = ({className, text, type, isCapture}) => {
    const {displayPercent, displayBalance} = useFormatNumber()
    const {colorStyle} = useContext(SnsShareContext)

    const textColorStyle = useMemo(() => {
        if (type === 'profit' || type === 'earning') {
            if (text > 0) {
                return colorStyle.mainColor.plus
            } else if (text < 0) {
                return colorStyle.mainColor.minus
            } else {
                return colorStyle.mainColor.base
            }
        } else {
            return colorStyle.mainColor.base
        }
    }, [text, colorStyle, type])

    const signText = useMemo(() => {
        if (type === 'profit') {
            if (text > 0) {
                return `+ ${displayPercent(text)}`
            } else {
                return displayPercent(text)
            }
        } else if (type === 'earning') {
            if (text > 0) {
                return `+ ${displayBalance(text, {showPreSign: true})}`
            } else {
                return displayBalance(text, {showPreSign: true})
            }
        } else {
            return displayBalance(text, {showPreSign: true})
        }
    }, [text, type, displayBalance, displayPercent])

    return (
        <>
            <div>
                <Text
                    enablePreWhiteSpace={false}
                    className={`font-bold text-[37px] whitespace-nowrap break-normal ${textColorStyle}`}>
                    {signText}
                </Text>
            </div>
        </>
    )
}

export default PortfolioShareMainNumber
