import React, {useContext, useMemo} from 'react'
import Text from '@component/Text/Text'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import {useTranslation} from 'next-i18next'

interface IProps {
    className?: string
    allDate?: number
}

const CurrentDateBadge: React.FC<IProps> = ({className, allDate}) => {
    const {t} = useTranslation()
    const {currentDate, colorStyle} = useContext(SnsShareContext)
    const getRenderDate = useMemo(() => {
        if (currentDate === '1000') {
            const now = new Date().getTime()
            return t(`modal.snsShare.card.date.all`, {
                day: allDate,
            })
        } else {
            return t(`modal.snsShare.card.date.${currentDate}`)
        }
    }, [currentDate, allDate, t])

    return (
        <div className={`${colorStyle.dateTagColor} w-fit px-[7px] pt-[3px] pb-[1px] rounded-[15px] ${className}`}>
            <Text
                enablePreWhiteSpace={false}
                className={`text-[11px] whitespace-nowrap break-normal ${colorStyle.text}`}>
                {getRenderDate}
            </Text>
        </div>
    )
}

export default CurrentDateBadge
