import React, {useContext, useMemo, useRef} from 'react'
import Text from '@component/Text/Text'
import MoreButton from '@feature/common/components/MoreButton/MoreButton'
import EditDesignContainer from '@feature/common/components/PortfolioShareModal/edit/EditDesignContainer'
import EditShareItemContainer from '@feature/common/components/PortfolioShareModal/edit/EditShareItemContainer'
import EditCurrentDateContainer from '@feature/common/components/PortfolioShareModal/edit/EditCurrentDateContainer'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import {useTranslation} from 'next-i18next'
import EditDisplayNameContainer from '@feature/common/components/PortfolioShareModal/edit/EditDisplayNameContainer'
import ShareImageContainer from '@feature/common/components/PortfolioShareModal/share/ShareImageContainer'
import IconSave from '@svg/common/ic_save.svg'
import {domToJpeg} from 'modern-screenshot'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
}

const UserActionEditContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {viewPage, shareItem} = useContext(SnsShareContext)

    const divRef = useRef<HTMLDivElement>(null)

    const onClickShare = async () => {
        if (!divRef.current) return

        setTimeout(async () => {
            domToJpeg(divRef.current, {scale: 2, quality: 1}).then(dataUrl => {
                var link = document.createElement('a')
                link.download = `League of Traders_${new Date().getTime()}`
                link.href = dataUrl
                link.click()
            })
        }, 500)
    }

    const isCheckDisabled = useMemo(() => {
        return shareItem.length === 0
    }, [shareItem])

    return (
        <>
            <div className={`p-[20px] flex flex-col h-full ${className}`}>
                <div>
                    <Text className={'text-h3 text-gray01'}>{t('modal.snsShare.edit.title')}</Text>
                    <Text className={'pt-[5px] text-body3 text-gray02'}>{t('modal.snsShare.edit.subtitle')}</Text>
                </div>
                {viewPage === 'front' ? (
                    <div className={'mt-[20px] space-y-[30px] flex-1'}>
                        <EditDesignContainer />
                        <EditShareItemContainer />
                        <EditCurrentDateContainer />
                        <EditDisplayNameContainer />
                    </div>
                ) : (
                    <div className={'mt-[20px] space-y-[30px] flex-1 flex flex-col'}>
                        <EditDesignContainer />
                        <EditDisplayNameContainer />

                        <div className={'flex-1 flex items-center'}>
                            <Text className={'text-body2 text-gray02'}>{t('modal.snsShare.edit.option.qr.desc')}</Text>
                        </div>
                    </div>
                )}
                <div className={'my-[10px] md:my-[50px]'}>
                    <MoreButton
                        againSecond={3000}
                        disabled={isCheckDisabled}
                        className={`min-w-[150px] gap-x-[10px] mx-auto border border-gray06 ${
                            isCheckDisabled ? 'dark:bg-gray07' : 'dark:hover:bg-gray09 dark:active:bg-gray07'
                        }`}
                        text={t('modal.snsShare.edit.share.button')}
                        textClassName={`${isCheckDisabled ? 'dark:text-gray04' : 'dark:text-gray01'}`}
                        renderLeft={
                            <IconSave
                                className={`w-[16px] ${shareItem?.length === 0 ? 'fill-gray04' : 'fill-gray01'}`}
                            />
                        }
                        onClick={() => {
                            createLog('event', 'portfolio_share_click_save_button')
                            onClickShare()
                        }}
                    />
                </div>
            </div>
            <div className={'absolute top-[-9999px] left-[-9999px]'}>
                <ShareImageContainer ref={divRef} />
            </div>
        </>
    )
}

export default UserActionEditContainer
