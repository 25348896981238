import React from 'react'
import Text from '@component/Text/Text'
import IconNext from '@svg/common/ic_btn_next.svg'
import Link from 'next/link'

interface IProps {
    text?: string
    className?: string
    onClick?: () => void
    showArrow?: boolean
    textClassName?: string
    dataCy?: string
    titleHref?: string
}

const Title: React.FC<IProps> = ({
    text,
    textClassName = 'text-ti1 text-gray01 dark:text-dark_gray01',
    className,
    onClick,
    showArrow = false,
    dataCy,
    titleHref,
}) => {
    if (titleHref) {
        return (
            <Link
                href={titleHref}
                onClick={onClick}
                data-cy={dataCy}
                className={`${className} flex items-center gap-x-[5px]`}>
                <Text className={`${textClassName}`}>{text}</Text>
                {showArrow && <IconNext className={'w-[14px] fill-gray01 dark:fill-dark_gray01'} />}
            </Link>
        )
    } else {
        return (
            <button
                data-cy={dataCy}
                className={`${className} flex items-center gap-x-[5px]`}
                onClick={onClick}
                disabled={!onClick}>
                <Text className={`${textClassName}`}>{text}</Text>
                {showArrow && <IconNext className={'w-[14px] fill-gray01 dark:fill-dark_gray01'} />}
            </button>
        )
    }
}

export default Title
