import React, {createContext, useMemo, useState} from 'react'
import FlipCardContainer from '@feature/common/components/PortfolioShareModal/FlipCardContainer'
import UserActionEditContainer from '@feature/common/components/PortfolioShareModal/UserActionEditContainer'
import {TypeShareTheme} from '@feature/common/components/PortfolioShareModal/edit/EditDesignContainer'
import {IProfitSummary} from '@type/profile'
import {useLoginStore} from '@store/LoginStore'
import useQueryGetUserGraph from '@hook/query/portfolio/useQueryGetUserGraph'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'

interface IProps {
    className?: string
    profitData?: IProfitSummary
    isFirst?: boolean
    setIsFirst?: (isFirst: boolean) => void
}

type TypeShareOption = {displayName: boolean}
type TypeViewPage = 'front' | 'back'
type TypeUserAction = 'edit' | 'share'

export const themePalette = (theme: string) => {
    if (theme === 'primary') {
        return {
            verified: 'text-white',
            mainColor: {
                plus: 'text-[#7EEBC4]',
                minus: 'text-[#FF5050]',
                base: 'text-white',
            },
            subColor: {
                plus: 'text-[#7EEBC4]',
                minus: 'text-[#FF5050]',
                base: 'text-white',
            },
            chartColor: {
                plus: '#7EEBC4',
                minus: '#FF5050',
                balance: '#7EE4EB',
                portfolio: {
                    base: '#EEEEEE33',
                },
            },
            QRCodeFgColor: '#FFFFFF',
            dateTagColor: 'bg-[#435962]',
            nameColor: 'text-[#CACACA]',
            text: 'text-gray08',
            bgColor: '#2A3547',
        }
    } else if (theme === 'blue') {
        return {
            verified: 'text-black',
            mainColor: {
                plus: 'text-[#00A490]',
                minus: 'text-[#D7434D]',
                base: 'text-[#2A3547]',
            },
            subColor: {
                plus: 'text-[#00675B]',
                minus: 'text-[#C83640]',
                base: 'text-[#2A3547]',
            },
            chartColor: {
                plus: '#0FB5BF',
                minus: '#E65862',
                balance: '#67A0CA',
                portfolio: {
                    base: '#00000026',
                },
            },
            QRCodeFgColor: '#FEFEFE',
            dateTagColor: 'bg-[#F2F2F2]',
            nameColor: 'text-[#2A3547]',
            text: 'text-[#2A3547]',
            bgColor: '#FFFFFF80',
        }
    }
}

export const SnsShareContext = createContext({
    themeColor: 'primary',
    setThemeColor: e => {},
    shareItem: ['profit', 'balance', 'earning', 'portfolio'],
    setShareItem: e => {},
    currentDate: '7',
    setCurrentDate: e => {},
    option: {displayName: true},
    setOption: e => {},
    viewPage: 'front',
    setViewPage: e => {},
    userAction: 'edit',
    setUserAction: e => {},
    colorStyle: themePalette('primary'),
    isSwiping: false,
    setIsSwiping: e => {},
    randomNumber: 1,
    setRandomNumber: e => {},
})

const PortfolioShareContainer: React.FC<IProps> = ({className, profitData, isFirst, setIsFirst}) => {
    const [themeColor, setThemeColor] = useState<TypeShareTheme>('primary')
    const [shareItem, setShareItem] = useState(['profit', 'balance', 'earning', 'portfolio'])
    const [currentDate, setCurrentDate] = useState<string>('1000')
    const [option, setOption] = useState<TypeShareOption>({displayName: true})
    const [viewPage, setViewPage] = useState<TypeViewPage>('front')
    const [userAction, setUserAction] = useState<TypeUserAction>('edit')
    const [colorStyle, setColorStyle] = useState(themePalette('primary'))
    const [isSwiping, setIsSwiping] = useState(false)

    const [randomNumber, setRandomNumber] = useState(1)
    const loginId = useLoginStore(state => state.id)
    const {chartGraphData, refresh} = useQueryGetUserGraph(loginId, 1000)

    useIsomorphicLayoutEffect(() => {
        if (themeColor === 'primary') {
            setColorStyle(themePalette('primary'))
        } else if (themeColor === 'blue') {
            setColorStyle(themePalette('blue'))
        }
    }, [themeColor])

    const maxNumber = useMemo(() => {
        const numberArr = Object?.values(profitData || {})
            ?.slice(1)
            ?.map(item => {
                return item?.profit
            })
        return numberArr
    }, [profitData])

    useIsomorphicLayoutEffect(() => {
        const maxIndex = maxNumber?.findIndex(item => Math.max(...maxNumber) === item)

        if (chartGraphData?.length > 0) {
            if (!isFirst) {
                setCurrentDate(Object?.keys(profitData)?.slice(1)?.at(maxIndex))
                setIsFirst(true)
            }
        } else {
            setCurrentDate('1000')
        }

        setRandomNumber(Math.floor(Math.random() * 6) + 1)
    }, [maxNumber, chartGraphData])

    return (
        <SnsShareContext.Provider
            value={{
                themeColor,
                setThemeColor,
                shareItem,
                setShareItem,
                currentDate,
                setCurrentDate,
                option,
                setOption,
                viewPage,
                setViewPage,
                colorStyle,
                userAction,
                setUserAction,
                isSwiping,
                setIsSwiping,
                randomNumber,
                setRandomNumber,
            }}>
            <div className={'flex md:flex-col'}>
                <FlipCardContainer />
                <div className={'flex-1'}>
                    <UserActionEditContainer />
                </div>
            </div>
        </SnsShareContext.Provider>
    )
}

export default PortfolioShareContainer
