import React, {useCallback, useContext, useMemo} from 'react'
import Text from '@component/Text/Text'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import {useTranslation} from 'next-i18next'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
}

export interface IShareTheme {
    id: string
    colorClass: TypeShareTheme
}

export type TypeShareTheme = 'primary' | 'blue'
const EditDesignContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {themeColor, setThemeColor} = useContext(SnsShareContext)

    const themeList = useMemo(() => {
        return [
            {id: 'primary', colorClass: 'bg-[#17B883]'},
            {id: 'blue', colorClass: 'bg-gradient-to-b from-[#A7D3F0] to-[#ABEED8]'},
        ]
    }, [])

    const getActiveThemeStyle = useCallback(
        (theme: IShareTheme) => {
            if (theme?.id === themeColor) {
                return `after:content-[''] after:block after:w-[22px] after:h-[22px] after:border-[1.5px] after:border-white after:absolute after:rounded-[4px] after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-50%]`
            }
        },
        [themeColor],
    )

    const onClickThemeColor = theme => {
        createLog('event', 'portfolio_share_change_theme', {theme: theme?.id})
        setThemeColor(theme?.id)
    }

    return (
        <div className={'flex items-center justify-between'}>
            <Text className={'text-ti2 text-gray01'}>{t('modal.snsShare.edit.option.design')}</Text>
            <div className={'flex items-center space-x-[15px]'}>
                {themeList?.map((theme: IShareTheme) => {
                    return (
                        <div
                            onClick={() => onClickThemeColor(theme)}
                            key={theme?.id}
                            className={`w-[25px] h-[25px] relative rounded-[5px] cursor-pointer ${getActiveThemeStyle(
                                theme,
                            )} ${theme?.colorClass}`}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default EditDesignContainer
