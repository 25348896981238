import React, {useContext} from 'react'
import QR from 'qrcode.react'
import {useRouter} from 'next/router'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'

interface IProps {
    className?: string
    size?: number
}

const ReferralQR: React.FC<IProps> = ({className, size}) => {
    const router = useRouter()
    const {data: me} = useQueryFetchMe()
    const {themeColor, colorStyle} = useContext(SnsShareContext)

    return (
        <div>
            <QR
                size={size}
                level={'L'}
                renderAs={'svg'}
                fgColor={themeColor === 'primary' ? colorStyle?.QRCodeFgColor : '#2A3547'}
                bgColor={themeColor === 'primary' ? '#2A3547' : '#D1E8E5'}
                value={`https://leagueoftraders.io${router.locale === 'ko' ? '/ko' : ''}/login?referral=${
                    me?.my_referral_code
                }`}
            />
        </div>
    )
}

export default ReferralQR
