import React, {useContext, useMemo, useState} from 'react'
import Text from '@component/Text/Text'
import {SnsShareContext} from '@feature/common/components/PortfolioShareModal/PortfolioShareContainer'
import useQueryGetUserGraph from '@hook/query/portfolio/useQueryGetUserGraph'
import {useLoginStore} from '@store/LoginStore'
import {getDateFromYYYYMMDD} from '@util/dates'
import PortfolioShareChart from '@feature/common/components/PortfolioShareModal/component/PortfolioShareChart'
import PortfolioShareMainNumber from '@feature/common/components/PortfolioShareModal/component/PortfolioShareMainNumber'
import useFormatNumber from '@hook/useFormatNumber'
import CurrentDateBadge from '@feature/common/components/PortfolioShareModal/component/CurrentDateBadge'
import {useTranslation} from 'next-i18next'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'

interface IProps {
    className?: string
    isFirst?: boolean
    isCapture?: boolean
}

const ProfitDisplay: React.FC<IProps> = ({className, isFirst, isCapture}) => {
    const {t} = useTranslation()
    const loginId = useLoginStore(state => state.id)
    const [renderText, setRenderText] = useState(0)
    const {currentDate, themeColor, colorStyle} = useContext(SnsShareContext)

    const {displayPercent} = useFormatNumber()
    const {chartGraphData, refresh} = useQueryGetUserGraph(loginId, parseInt(currentDate))

    const chartData = useMemo(() => {
        if (!chartGraphData || chartGraphData.length === 0) return []

        return chartGraphData.map(item => {
            const date = getDateFromYYYYMMDD(item.day).getTime()
            return {x: date, y: item.score}
        })
    }, [chartGraphData])

    const renderStyle = useMemo(() => {
        if (renderText > 0) {
            return colorStyle.subColor.plus
        } else if (renderText < 0) {
            return colorStyle.subColor.minus
        } else {
            return colorStyle.subColor.base
        }
    }, [renderText, colorStyle])

    useIsomorphicLayoutEffect(() => {
        if (chartData?.at(-1)?.y) {
            setRenderText(chartData?.at(-1)?.y)
        }
    }, [chartData])

    return isFirst ? (
        <div className={`flex flex-col justify-center items-center ${colorStyle.text}`}>
            <Text className={`text-[15px] font-semibold`}>{t('modal.snsShare.card.profit')}</Text>
            <PortfolioShareMainNumber text={renderText} type={'profit'} isCapture={isCapture} />
            <CurrentDateBadge allDate={chartGraphData?.length} />
            <div className={`mb-[15px] min-h-[90px] ${chartData?.length > 0 && 'h-[90px]'}`}>
                <PortfolioShareChart
                    data={chartData}
                    width={230}
                    height={90}
                    type={'profit'}
                    className={'top-[-5px]'}
                />
            </div>
        </div>
    ) : (
        <div className={'flex justify-between items-center px-[25px]'}>
            <Text className={`text-[13px] ${colorStyle?.nameColor}`}>{t('modal.snsShare.card.profit')}</Text>
            <Text
                enablePreWhiteSpace={false}
                className={`text-[15px] font-semibold whitespace-nowrap break-normal ${renderStyle}`}>
                {renderText > 0 ? `+ ${displayPercent(renderText)}` : displayPercent(renderText)}
            </Text>
        </div>
    )
}

export default ProfitDisplay
